import { defineStore } from 'pinia'
import { apiHandler, ServerResponse } from '@/api/ApiHandler'
import { User, USER_ROLES } from '@/model/User'
import { TokenData } from '@/model/TokenData'
import { goTo } from '@/router'
import { utils } from '@/common/Utils'
import { loadLanguageAsync } from '@/locales'
import { DigUiView } from '@digithia/ui'
import { Admin } from '@/model/Admin'
import { Player } from '@/model/Player'
import { useStoreGame } from './game'

export interface StoreStateAuth {
  currentPage: string
  token: string
  tokendata: TokenData | undefined
  playerDisabled: boolean
  projectImage: string
  lang: string
}

export const useStoreAuth = defineStore('auth', {
  state: (): StoreStateAuth => ({
    currentPage: '',
    get token(): string {
      return localStorage.getItem('token') || ''
    },
    set token(token: string) {
      localStorage.setItem('token', token || '')
    },
    get tokendata(): TokenData | undefined {
      return localStorage.getItem('tokendata')
        ? JSON.parse(localStorage.getItem('tokendata') || '{}')
        : undefined
    },
    set tokendata(tokendata: TokenData | undefined) {
      localStorage.setItem('tokendata', JSON.stringify(tokendata || ''))
    },
    playerDisabled: false,
    projectImage: ``,
    lang: 'fr',
  }),
  getters: {
    user: (state): User | undefined => {
      return state.tokendata?.user
    },
    admin: (state): Admin | undefined => {
      return state.tokendata?.user?.admin
    },
    player: (state): Player | undefined => {
      return state.tokendata?.user?.player
    },
    role: (state): USER_ROLES | undefined => {
      return state.tokendata?.user?.role
    },
    isAdmin: (state): boolean => {
      return state.tokendata?.user?.role === USER_ROLES.ADMIN
    },
    isPlayer: (state): boolean => {
      return state.tokendata?.user?.role === USER_ROLES.PLAYER
    },
    isSuperadmin: (state): boolean => {
      return state.tokendata?.user?.role === USER_ROLES.SUPERADMIN
    },
    isLoggedIn: (state): boolean => {
      return (
        !!state.token &&
        new Date().getTime() < (state.tokendata?.exp || 0) * 1000
      )
    },
  },
  actions: {
    async login(
      username: string,
      password?: string,
    ): Promise<ServerResponse<string>> {
      const json = await apiHandler.post('users/login', { username, password })
      if (json.success) {
        this.setToken(json.data)
      }
      return json
    },
    async activateAccount(
      id: number,
      password: string,
    ): Promise<ServerResponse<string>> {
      const json = await apiHandler.post(`users/${id}/activate-account`, {
        password,
      })
      if (json.success) {
        this.setToken(json.data)
      }
      return json
    },
    async updateAccount(
      username: string,
      email: string,
    ): Promise<ServerResponse<void>> {
      const json = await apiHandler.put(`users/update-self`, {
        username,
        email,
      })
      return json
    },
    async resetPassword(password: string): Promise<ServerResponse<string>> {
      const json = await apiHandler.post(
        `users/${this.user?.id}/reset-password`,
        {
          password,
        },
      )
      if (json.success) {
        this.setToken(json.data)
      }
      return json
    },
    async askResetPassword(username: string): Promise<ServerResponse<void>> {
      const json = await apiHandler.post('users/ask-reset-password', {
        username,
      })
      return json
    },
    async changePassword(password: string): Promise<ServerResponse<void>> {
      const json = await apiHandler.put(`users/change-password`, {
        password,
      })
      return json
    },
    logout(): void {
      const storeGame = useStoreGame()

      this.token = ''
      this.tokendata = {}

      const html = document.querySelector('html')
      const gameClass = storeGame.journey?.gameClass
      if (gameClass) html?.classList.remove(gameClass)
    },
    setToken(token: string): void {
      this.token = token
      this.extractTokenData()
      this.playerDisabled = false
    },
    disablePlayer(): void {
      this.playerDisabled = true
      goTo({ name: 'Disabled' })
    },
    extractTokenData(): void {
      if (!this.token) {
        this.tokendata = undefined
        return
      }
      const base64Url = this.token.split('.')[1]
      if (!base64Url) {
        this.tokendata = undefined
        return
      }
      const base64 = base64Url.replace('-', '+').replace('_', '/')
      const tokendata = JSON.parse(window.atob(base64) || '{}')
      this.tokendata = tokendata
    },
    changeLang(lang: string) {
      this.lang = lang
      utils.locale = lang
      loadLanguageAsync(lang).then(() => {
        const view = document.querySelector('dig-ui-view') as DigUiView | null
        view?.refreshSideBar()
      })
    },
    updatePage(page: string): void {
      this.currentPage = page
    },
  },
})

import { __ } from '@/locales'
import { useStoreAuth } from '@/store/auth'
import { useStoreGame } from '@/store/game'
import dayjs from 'dayjs'
import { Answer } from './Answer'
import { Item } from './Item'
import { User } from './User'

export enum PLAYER_STATES {
  CREATED = 'created',
  STARTED = 'started',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
  TERMINATED = 'terminated',
}

export enum JOURNEY_IDS {
  PARCOURSNORD = `parcours-nord`,
  PARCOURSSUD = `parcours-sud`,
  CREALAB = `crealab`,
  DEMO1 = `demo-1`,
}

export const onlyUnique = (value: unknown, index: number, self: unknown[]) => {
  return self.indexOf(value) === index
}

export interface Player extends Item {
  id: number
  user?: User
  userId?: number
  answers?: Answer[]
  answerIds?: number[]
  journeyId?: JOURNEY_IDS
  firstname?: string
  lastname?: string
  phone?: string
  address?: string
  city?: string
  cp?: string
  state?: PLAYER_STATES
  alternative?: boolean
  createdAt?: Date
  startedAt?: Date
  completedAt?: Date
  score?: string
  comment?: string
  note?: string
}

export interface IFormPlayerReward {
  firstname?: string
  lastname?: string
  address?: string
  city?: string
  cp?: string
  phone?: string
}

export const searchPlayer = (players: Player[], search: string) => {
  return players.filter((player) => {
    return (
      (player.firstname &&
        player.firstname.toLowerCase()?.match(search.toLowerCase())) ||
      (player.lastname &&
        player.lastname.toLowerCase()?.match(search.toLowerCase())) ||
      (player.startedAt &&
        dayjs(player.startedAt)
          .format('DD/MM/YYYY')
          .match(search.toLowerCase())) ||
      (player.user?.username &&
        player.user?.username.toLowerCase()?.match(search.toLowerCase())) ||
      (player.state &&
        __(`Player.States.${player.state}`)
          .toLowerCase()
          ?.match(search.toLowerCase())) ||
      (player.user?.email &&
        player.user?.email.toLowerCase()?.match(search.toLowerCase()))
    )
  })
}

export const sortPlayer = (players: Player[], filter: string) => {
  return players.sort((a, b) => {
    if (filter.match('createdAt')) {
      if (filter.match('-')) {
        return (a.createdAt || 0) > (b.createdAt || 0) ? -1 : 1
      } else {
        return (a.createdAt || 0) > (b.createdAt || 0) ? 1 : -1
      }
    }
    if (filter.match('startedAt')) {
      if (filter.match('-')) {
        return (a.startedAt || 0) > (b.startedAt || 0) ? -1 : 1
      } else {
        return (a.startedAt || 0) > (b.startedAt || 0) ? 1 : -1
      }
    }
    if (filter.match('firstname')) {
      if (!a.firstname && b.firstname) return 1
      if (!b.firstname && a.firstname) return -1
      if (filter.match('-')) {
        return b.firstname?.localeCompare(a.firstname || '') || 0
      } else {
        return a.firstname?.localeCompare(b.firstname || '') || 0
      }
    }
    if (filter.match('lastname')) {
      if (!a.lastname && b.lastname) return 1
      if (!b.lastname && a.lastname) return -1
      if (filter.match('-')) {
        return b.lastname?.localeCompare(a.lastname || '') || 0
      } else {
        return a.lastname?.localeCompare(b.lastname || '') || 0
      }
    }
    if (filter.match('username')) {
      if (!a.user?.username && b.user?.username) return 1
      if (!b.user?.username && a.user?.username) return -1
      if (filter.match('-')) {
        return b.user?.username?.localeCompare(a.user?.username || '') || 0
      } else {
        return a.user?.username?.localeCompare(b.user?.username || '') || 0
      }
    }
    if (filter.match('state')) {
      if (!a.state && b.state) return 1
      if (!b.state && a.state) return -1
      if (filter.match('-')) {
        return (
          __(`Player.States.${b.state}`)?.localeCompare(
            __(`Player.States.${a.state}`),
          ) || 0
        )
      } else {
        return (
          __(`Player.States.${a.state}`)?.localeCompare(
            __(`Player.States.${b.state}`),
          ) || 0
        )
      }
    }
    return 0
  })
}

export const loadAllData = async () => {
  const storeAuth = useStoreAuth()
  const storeGame = useStoreGame()

  if (!storeAuth.isLoggedIn) {
    return
  }

  await storeGame.getVersion()
  await storeGame.getPlayer()

  const mainDataPromise: Promise<unknown>[] = []

  mainDataPromise.push(storeGame.getProject())
  mainDataPromise.push(storeGame.getJourney())
  mainDataPromise.push(storeGame.getAnswers())

  await Promise.all(mainDataPromise)

  const answers = storeGame.answers
  const currentAnswer =
    answers.find((a) => !a.completed) || answers.find((a) => a.order === 1)

  storeGame.selectCurrentAnswer(currentAnswer?.id)
  const allImagesPromise = []

  const journey = storeGame.journey
  const journeyImages: (string | undefined)[] = []
  const stages = journey?.stages || []
  const steps = journey?.stages.map((stage) => stage.steps.flat()).flat() || []

  journeyImages.push(...(journey?.outroImages || []))
  journeyImages.push(...(journey?.introImages || []))
  journeyImages.push(
    ...(stages
      .map((stage) => stage.interestPoints?.map((i) => i.images).flat())
      .flat() || []),
  )

  journeyImages.push(
    ...steps
      .map((step) =>
        [
          step.parallaxImage,
          ...(step.parts?.map((part) => part.images) || []).flat(),
          step.pointAndClick?.image,
          ...(step.pointAndClick?.interactions.map((i) => i.image) || []),
          ...(step.pointAndClick?.interactions.map(
            (i) => i.pointAndClick?.image,
          ) || []),
          ...(step.pointAndClick?.interactions.map((i) =>
            i.pointAndClick?.interactions.map((i) => i.image),
          ) || []),
        ].flat(),
      )
      .flat(),
  )

  for (const image of journeyImages.filter((i) => i).filter(onlyUnique)) {
    if (image) {
      allImagesPromise.push(storeGame.getImage(image))
    }
  }

  await Promise.all(allImagesPromise)

  const html = document.querySelector('html')
  html?.classList.add(storeGame.journey?.gameClass || '')
}

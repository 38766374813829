<template>
  <div class="game-container-wrapper">
    <div class="game-container">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.game-container-wrapper {
  display: flex;
  justify-content: center;
}

.game-container {
  padding: 0px 5vw;
  max-width: 900px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .base-container {
    padding: 0px 12px;
  }
}

@media screen and (max-width: 600px) {
  .base-container {
    padding: 0px 6px;
  }
}
</style>

import { __ } from '@/locales'
import { Toast } from '@/model/Toast'
import { goTo } from '@/router'
import { useStoreAuth } from '@/store/auth'

export enum Methods {
  get = 'GET',
  post = 'POST',
  put = 'PUT',
  delete = 'DELETE',
}

export interface ServerResponse<T> {
  code: number
  data?: T
  error?: string | unknown
  success?: boolean
  type?: string
  message?: string
  status?: number
}

class ApiHandler {
  baseUrl = `${process.env.VUE_APP_API_URL}api/`

  get token(): string {
    const storeAuth = useStoreAuth()
    return storeAuth.token
  }

  get lang(): string {
    const storeAuth = useStoreAuth()
    return storeAuth.lang
  }

  headers = {
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }

  async get(path: string) {
    const storeAuth = useStoreAuth()
    const request: RequestInit = {
      method: 'GET',
      headers: {
        ...this.headers,
        Authorization: this.token,
        'Accept-Language': this.lang,
      },
    }

    this.logRequest(path, request)

    return fetch(`${this.baseUrl}${path}`, request)
      .then(async (response) => {
        const json = await response.json()
        this.logResponse(json, path, Methods.get)
        if (json.code === 'token_expired') {
          storeAuth.logout()
        }
        if (json.code === 'player_disabled') {
          goTo({ name: 'Disabled' })
          storeAuth.disablePlayer()
        }
        return json
      })
      .catch((error) => this.handleError(error, path, request.method))
  }

  async post(path: string, body: unknown = {}) {
    const storeAuth = useStoreAuth()
    const request = {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        ...this.headers,
        Authorization: this.token,
        'Accept-Language': this.lang,
      },
    }

    this.logRequest(path, request)

    return fetch(`${this.baseUrl}${path}`, request)
      .then(async (response) => {
        const json = await response.json()
        this.logResponse(json, path, Methods.get)
        if (json.code === 'token_expired') {
          storeAuth.logout()
        }
        if (json.code === 'player_disabled') {
          goTo({ name: 'Disabled' })
          storeAuth.disablePlayer()
        }
        return json
      })
      .catch((error) => this.handleError(error, path, request.method))
  }

  async put(path: string, body: unknown = {}) {
    const storeAuth = useStoreAuth()
    const request = {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        ...this.headers,
        Authorization: this.token,
        'Accept-Language': this.lang,
      },
    }

    this.logRequest(path, request)

    return fetch(`${this.baseUrl}${path}`, request)
      .then(async (response) => {
        const json = await response.json()
        this.logResponse(json, path, Methods.get)
        if (json.code === 'token_expired') {
          storeAuth.logout
        }
        if (json.code === 'player_disabled') {
          goTo({ name: 'Disabled' })
          storeAuth.disablePlayer()
        }
        return json
      })
      .catch((error) => this.handleError(error, path, request.method))
  }

  async delete(path: string, body: unknown = {}) {
    const storeAuth = useStoreAuth()
    const request = {
      method: 'DELETE',
      body: JSON.stringify(body),
      headers: {
        ...this.headers,
        Authorization: this.token,
        'Accept-Language': this.lang,
      },
    }

    this.logRequest(path, request)

    return fetch(`${this.baseUrl}${path}`, request)
      .then(async (response) => {
        const json = await response.json()
        this.logResponse(json, path, Methods.get)
        if (json.code === 'token_expired') {
          storeAuth.logout
        }
        if (json.code === 'player_disabled') {
          goTo({ name: 'Disabled' })
          storeAuth.disablePlayer()
        }
        return json
      })
      .catch((error) => this.handleError(error, path, request.method))
  }

  handleError(error: Error, path: string, method?: string): Toast {
    console.error('Escape Game API error : ', error)
    if (error.message.match(`JSON.parse`)) {
      error.message =
        __(`cannotConnectToServer`) + ` : ${method} ${this.baseUrl}${path}`
    }

    return { type: 'error', message: error.message }
  }

  handleResponse(response: Response) {
    console.log(response)
    return response.json()
  }

  logRequest(url: string, header: RequestInit) {
    console.debug(`Server call on ${this.baseUrl}${url}`, header)
  }

  logResponse(json: ServerResponse<unknown>, url: string, method: string) {
    if (process.env.VUE_APP_MODE === 'dev') {
      const response = { ...json }
      if (
        response &&
        response.data &&
        (response.data as unknown[]).length > 200
      ) {
        response.data = `${response.data}`.slice(0, 100)
      }
      console.debug(
        `Server call on ${method} ${url} and response with : `,
        response,
      )
    }
  }
}

export const apiHandler = new ApiHandler()

export interface Country {
  lang: string
  flag: string
  name: string
}

export const countries: Country[] = [
  { lang: 'fr', flag: '🇫🇷', name: 'Français' },
  { lang: 'en', flag: '🇬🇧', name: 'English' },
  { lang: 'de', flag: '🇩🇪', name: 'Deutsch' },
]

// import { __ } from '@/locales'
import { PLAYER_STATES } from '@/model/Player'
import { useStoreAuth } from '@/store/auth'
import { useStoreGame } from '@/store/game'
import { NavigationGuardWithThis, RouteRecordRaw } from 'vue-router'
import ViewHome from '../views/player/ViewHome.vue'
import ViewMain from '../views/player/ViewMain.vue'

export const playerRoutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Main',
    component: ViewMain,
    redirect: 'home',
    children: [
      {
        path: 'home',
        name: 'Home',
        component: ViewHome,
      },
      {
        path: 'play',
        name: 'Play',
        component: () => import('../views/player/ViewPlay.vue'),
      },
      {
        path: 'disabled',
        name: 'Disabled',
        component: () => import('../views/player/ViewDisabled.vue'),
      },
      {
        path: 'begin',
        name: 'Begin',
        component: () => import('../views/player/ViewBegin.vue'),
      },
      {
        path: 'satisfaction',
        name: 'Satisfaction',
        component: () => import('../views/player/ViewSatisfaction.vue'),
      },
      {
        path: 'end',
        name: 'End',
        component: () => import('../views/player/ViewEnd.vue'),
      },
      {
        path: 'interests',
        name: 'Interests',
        component: () => import('../views/player/ViewInterests.vue'),
      },
      {
        path: 'help',
        name: 'Help',
        component: () => import('../views/player/ViewHelp.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/player/ViewLogin.vue'),
  },
]

export const playerGuard: NavigationGuardWithThis<undefined> = async (
  to,
  from,
  next,
) => {
  const storeAuth = useStoreAuth()
  const storeGame = useStoreGame()

  storeAuth.updatePage(to.name?.toString() || '')

  if (to.query.token) {
    storeAuth.setToken(`${to.query.token}`)
  }

  if (!storeAuth.isLoggedIn || !storeAuth.isPlayer) {
    storeAuth.logout()
    if (to.name !== 'Login') {
      next({ name: 'Login' })
    } else {
      next()
    }
  } else if (storeAuth.isPlayer) {
    if (
      storeGame.player?.state &&
      storeGame.player?.state !== PLAYER_STATES.STARTED &&
      to.name !== 'Home' &&
      to.name !== 'Login' &&
      to.name !== 'Satisfaction' &&
      to.name !== 'End' &&
      to.name !== 'Disabled'
    ) {
      next({ name: 'Home' })
    } else {
      next()
    }
  } else {
    next()
  }
}

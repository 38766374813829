import { Admin } from './Admin'
import { Item } from './Item'
import { Player } from './Player'

export enum USER_ROLES {
  SUPERADMIN = 'superadmin',
  ADMIN = 'admin',
  PLAYER = 'player',
}

export interface User extends Item {
  id: number
  role?: USER_ROLES
  username?: string
  email?: string
  projectId?: string
  activated?: boolean
  admin?: Admin
  player?: Player
}

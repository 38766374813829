import { PLAYER_STATES } from '@/model/Player'
import { USER_ROLES } from '@/model/User'
import { LocaleMessages, VueMessageType } from 'vue-i18n'

const messages: LocaleMessages<VueMessageType> = {
  fr: {
    hello: `Bonjour`,
    login: `Me connecter`,
    cannotConnectToServer: `Impossible de se connecter au serveur`,
    validate: `Valider`,
    close: `Fermer`,
    show: `Afficher`,
    actions: `Actions`,
    search: `Rechercher`,
    edit: `Modifier`,
    cancel: `Annuler`,
    delete: `Supprimer`,
    editImage: `Modifier l'image`,
    addImage: `Ajouter une image`,
    invalidForm: `Formulaire invalide`,
    invalidPassword: `Mot de passe invalide`,
    passwordHackedIn: `Mot de passe déchiffré en {n} au maximum`,
    showPadlock: `Afficher cadenas`,
    hidePadlock: `Masquer cadenas`,
    chooseLang: `Choisir la langue`,
    thisIsYourLastHint: `Il n'y a plus d'indices !`,
    openInMap: `Découvrez l'adresse`,
    unlockIntro: `Afin de prouver votre présence sur le site,`,
    askAnHint: `Demander un indice`,
    hints: `Indices`,
    next: `Suite`,
    previous: `Précédent`,
    backToMenu: `Retour au menu`,
    errorLoadingData: `Les données n'ont pas pus être chargées`,
    reloadPage: `Rafraîchir la page`,
    back: `Retour`,
    bravo: `Bravo ! Le cadenas est ouvert`,
    iWantSolution: `Je veux voir la solution`,
    iDontWantSolution: `Je continue à chercher`,
    goToMap: `S'y rendre`,
    openAllInterests: `Points d'intérêt déjà visités`,
    validCode: `Code valide !`,
    invalidCode: `Code invalide`,
    quit: `Quitter`,
    showArea: `Afficher les zones cliquables`,
    hideArea: `Masquer les zones cliquables`,
    Countdown: {
      years: `{n} année | {n} années`,
      months: `{n} mois | {n} mois`,
      days: `{n} jour | {n} jours`,
      hours: `{n} heure | {n} heures`,
      minutes: `{n} minute | {n} minutes`,
      seconds: `{n} seconde | {n} secondes`,
    },
    expired: `Expiré`,
    HackedIn: {
      min: `moins de 1 seconde`,
      second: `{n} secondes`,
      minute: `{n} minutes`,
      hour: `{n} heures`,
      day: `{n} jours`,
      month: `{n} mois`,
      year: `{n} années`,
      thousand: `{n} millénaires`,
      million: `{n} millions d'années`,
      billion: `{n} milliard d'années`,
      max: `l'âge de l'univers`,
    },
    YesOrNo: {
      true: `Oui`,
      false: `Non`,
      null: `Non`,
      undefined: `Non`,
    },
    Yes: `Oui`,
    No: `Non`,
    OtherJourney: {
      first: `C'est ma première participation`,
      done: `J'ai déjà réalisé l'autre parcours`,
    },
    Nav: {
      title: `Mystery Tour`,
      notFound: `Introuvable`,
      Home: `Accueil`,
      Start: `Commencer`,
      Account: `Mon compte`,
      Login: `Connexion`,
      AskResetPassword: `Nouveau mot de passe`,
      ResetPassword: `Choix du mot de pase`,
      ActivateAccount: `Activer mon compte`,
      MyAccount: `Mon compte`,
      Logout: `Déconnexion`,
      MyAccountShort: `Compte`,
      LogoutShort: `Déco`,
      Users: `Utilisateurs`,
      Admins: `Les administrateurs`,
      Admin: `Administrateur`,
      Players: `Les joueurs`,
      Player: `Joueur`,
      PlayerPdf: `PDF du joueur`,
      CreatePlayer: `Créer un joueur`,
      Journey: `Parcours`,
      Journeys: `Les parcours`,
      Stage: `Étape`,
      Stages: `Étapes`,
      Answer: `Réponse`,
      Answers: `Réponses`,
      Previous: `Précédent`,
      CurrentAnswer: `Étape actuelle`,
      Disabled: `Compte désactivé`,
      Begin: `Début de partie`,
      End: `Partie terminée`,
      Play: `Jouer`,
      Help: `Aide`,
      Interests: `Points d'intérêt`,
      Projects: `Les projets`,
      Project: `Projet`,
      ProjectAdmins: `Les administrateurs de {n}`,
      ProjectPlayers: `Les joueurs de {n}`,
      Satisfaction: `Satisfaction`,
    },
    View: {
      NotFound: {
        title: `La page demandée n'existe pas`,
        button: `Retour au menu principal`,
      },
      ActivateAccount: {
        title: `Choisissez votre mot de passe pour activer votre compte`,
        validate: `Activer le compte`,
      },
      AskResetPassword: {
        title: `Demander un nouveau mot de passe`,
        message: `Pour réinitaliser votre mot de passe, veuillez saisir votre nom d'utilisateur ou votre adresse mail et suivez les instructions de l'email que vous recevrez.`,
        validate: `Valider`,
      },
      ResetPassword: {
        title: `Choisissez votre nouveau mot de passe`,
        validate: `Valider`,
      },
      Admin: {
        Login: {
          title: `Bienvenue sur le module d'administration de votre jeu de piste`,
          forgotMyPassword: `Mot de passe oublié`,
          askResetPassword: `Demande de nouveau mot de passe`,
          askResetPasswordMessage: `Pour obtenir un nouveau mot de passe, veuillez saisir votre nom d'utilisateur ou votre adresse mail et suivez les instructions de l'email que vous recevrez.`,
          validate: `Me connecter`,
        },
        Home: {
          title: `Jeu de piste du territoire`,
        },
        CreatePlayer: {
          title: `Ajouter un nouveau joueur`,
          validate: `Générer le code d'accès`,
          playerCreated: `Le joueur a bien été créé`,
          restart: `Ajouter un nouveau joueur`,
        },
        Player: {
          seePdf: `Afficher le PDF`,
          reinit: `Ré-initialiser la session du joueur`,
          restart: `Ajouter du temps (48H)`,
          hisJourney: `Son parcours : {n}`,
          create: `Créer un joueur`,
          activate: `Renvoyer l'email d'activation`,
        },
        User: {
          create: `Créer un administrateur`,
          changePassword: `Modifier mon mot de passe`,
        },
      },
      Player: {
        Login: {
          title: `Bienvenue dans le Mystery Tour.`,
          message: `Pour commencer la partie, entrez votre code d'accès et validez.`,
          login: `Valider`,
        },
        Home: {
          title: `Escape Game player`,
        },
        Answer: {
          goPrevious: `Retour au menu`,
          goNext: `Prochaine étape`,
          goPreviousStage: `Retour`,
          goNextStage: `Suite`,
          goIntro: `Retour à l'introduction`,
          openInterests: `Points d'intérêt à proximité`,
          goEnd: `Suivant`,
        },
        Start: {
          welcome: `Bienvenue {name}`,
          readyToStart: `Vous êtes maintenant prêt à démarrer votre partie.`,
          info: `Atttention, dès que vous aurez démarré votre partie, vous disposerez de 48h pour la terminer.`,
          journey: `Vous allez réaliser le parcours`,
          start: `Commencer ma partie`,
          completedOtherJourney: `Avez-vous déjà réalisé l'autre parcours ?`,
          timerStart: `Il vous reste encore {countdown} pour commencer votre parcours.`,
          timerContinue: `Il vous reste encore {countdown} pour finir votre parcours.`,
          warning: `Attention, il est impératif de respecter scrupuleusement le code de la route ainsi que l'environnement que vous allez visiter. \nL'office de tourisme de Saint-Avold Cœur de Moselle, ne peut en aucun cas porter la responsabilité d'une infraction commise lors de votre partie.`,
          virtual: `Le jeu vous enmène dans des lieux réels mais se déroule de manière entierement numérique. Aucun cadenas, contenant, papier etc... n'est à trouver sur place.`,
          intro: `Introduction`,
          firstStage: `Accéder à la première étape`,
          backToMenu: `Retour au menu de départ`,
          sessionStarted: `Votre session a commencé`,
          howManyAnswersCompleted: `Vous avez terminé {completed} étape sur {total}. | Vous avez terminé {completed} étape sur {total}. | Vous avez terminé {completed} étapes sur {total}.`,
          resumeGame: `Reprendre ma partie`,
          getYourReward: `Récupérez ma récompense`,
          quit: `Terminer ma partie`,
        },
        End: {
          title: `Aller chercher votre récompense`,
          getRewardPoste: `Je préfère recevoir ma récompense par voie postale`,
          alertReward: `Demander à recevoir ma récompense par voie postale`,
          linkToWebsite: `Lien vers le site internet`,
          credits: `Conçu et réalisé par Digithia et Out of Time`,
          accountDisabled: `Votre compte est désactivé.`,
          accountTimeout: `Votre temps est épuisé.`,
          exit: `Quitter ma partie`,
          howGetYourReward: `Comment souhaitez-vous récupérer votre récompense ?`,
          getReward1: `Je veux la récupérer sur place`,
          getReward2: `Je veux la recevoir par voie postale`,
          checkForm: `Complétez et vérifiez ce formulaire`,
          validateAndExit: `Valider ma demande et quitter`,
          gameOver: `Partie terminée`,
          Satisfaction: {
            stars: `Votre satisfaction`,
            comment: `Vos commentaires / suggestions :`,
            send: `Envoyer votre avis`,
            sent: `Votre avis est pris en compte.`,
            bypass: `Continuer sans donner d'avis`,
          },
        },
        Help: {
          title: `Vous avez besoin d'aide ?`,
          warning: `Nous vous rappellons que le jeu se passe dans des lieux réels mais se déroule de manière entierement numérique. Aucun cadenas, contenant, papier etc... n'est à trouver sur place.`,
          contact: `Si vous avez besoin de plus d'aide, contactez-nous :`,
          logout: `Pour vous déconnecter, cliquez ici :`,
          logoutButton: `Déconnexion`,
          contactPhone: `Par téléphone :`,
          contactEmail: `Par email :`,
          continue: `Pour continuer votre partie cliquez ici :`,
          continueButton: `Reprendre ma partie`,
        },
      },
    },
    User: {
      usernameOrEmail: `Nom d'utilisateur`,
      createDate: `Création`,
      password: `Mot de passe`,
      username: `Nom d'utilisateur`,
      email: `Email`,
      role: `Rôle`,
      Roles: {
        undefined: `Rôle non défini`,
        '': `Rôle non défini`,
        [USER_ROLES.ADMIN]: `Administrateur`,
        [USER_ROLES.PLAYER]: `Joueur`,
        [USER_ROLES.SUPERADMIN]: `Super-Administrateur`,
      },
      noData: `Aucun utilisateur n'a été trouvé`,
      activated: `Activé`,
      newPassword: `Nouveau mot de passe`,
      askActivateAccount: `Email d'activation`,
    },
    Player: {
      username: `Code d'accès`,
      usernameHint: `Laissez vide pour générer un code d'accès automatiquement`,
      email: `Email`,
      firstname: `Prénom`,
      lastname: `Nom`,
      phone: `Téléphone`,
      journey: `Parcours`,
      address: `Adresse`,
      city: `Ville`,
      cp: `Code postal`,
      state: `État`,
      States: {
        undefined: `État non défini`,
        '': `État non défini`,
        [PLAYER_STATES.CREATED]: `Créé`,
        [PLAYER_STATES.STARTED]: `Commencé`,
        [PLAYER_STATES.COMPLETED]: `Terminé`,
        [PLAYER_STATES.CANCELED]: `Annulé`,
        [PLAYER_STATES.TERMINATED]: `Terminé`,
      },
      createdAt: `Date d'achat`,
      startedAt: `Commencé le`,
      completedAt: `Terminé le`,
      askDelete: `Êtes-vous sûr de vouloir supprimer le joueur ?`,
      askRestart: `Êtes-vous sûr de vouloir ajouter du temps au joueur ?`,
      askReinit: `Êtes-vous sûr de vouloir re-initialiser le joueur ?`,
      createdUntil: `Temps avant de commencer`,
      startedUntil: `Temps avant de terminer`,
      score: `Satisfaction`,
      comment: `Commentaire`,
      sendEmail: `Envoyer un email au joueur`,
      note: `Notes diverses`,
      totalTime: `Temps total utilisé`,
      noData: `Aucun joueur n'a été trouvé`,
    },
    Journey: {
      stageCount: `Nombre d'étapes`,
      name: `Nom`,
      start: `Texte de départ`,
      createDate: `Création`,
      updateDate: `Modification`,
      playerCount: `Nombre de joueurs`,
      players: `Joueurs`,
      stages: `Étapes`,
      description: `Description`,
      descriptionAlternative: `Description alternative`,
      outro: `Outro`,
      outroAlternative: `Outro alternative`,
      hasAlternative: `Textes alternatifs`,
      hasReward: `Récupérer la récompense`,
      noData: `Aucun parcours n'a été trouvé`,
    },
    Stage: {
      name: `Nom`,
      createDate: `Création`,
      updateDate: `Modification`,
      order: `Ordre`,
      answerIds: `Réponses joueur`,
      title: `Titre`,
      address: `Adresse`,
      gps: `GPS`,
      codes: `Codes`,
      hints: `Indices`,
      padlock: `Cadenas`,
      padlockAlphabet: `Alphabet`,
      parallaxImage: `Image parallaxe`,
      noData: `Aucune étape n'a été trouvée`,
      steps: `Étapes`,
    },
    Step: {
      text: `Texte`,
    },
    Answer: {
      started: `Commencé`,
      startedAt: `Commencée le`,
      unlocked: `Débloqué`,
      unlockedAt: `Débloqué le`,
      completed: `Terminé`,
      completedAt: `Terminé le`,
      at: `Fait le`,
      hintAsk: `Indices demandés`,
      hintAt: `Indice {n} demandé à`,
      noData: `Aucune réponse n'a été trouvée`,
      state: `Partie`,
      State: {
        UNLOCK: `Déverrouillage`,
        COMPLETE: `Énigme`,
        TERMINATE: `Outro`,
      },
    },
    Project: {
      name: `Nom`,
      createDate: `Création`,
      updateDate: `Modification`,
      email: `Email`,
      phone: `Téléphone`,
      description: `Description`,
      schedule: `Horraires`,
      journeys: `Parcours`,
      users: `Nb Utilisateurs`,
      players: `Joueurs`,
      admins: `Administrateurs`,
      noData: `Aucun projet n'a été trouvé`,
      hisPlayers: `{n} joueur | {n} joueurs`,
      hisAdmins: `{n} administrateur | {n} administrateurs`,
      lang: `Langages`,
    },
    Admin: {
      project: `Projet`,
      noData: `Aucun administrateur n'a été trouvé`,
    },
  },
}

export default messages

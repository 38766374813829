import { defineStore } from 'pinia'
import { apiHandler, ServerResponse } from '@/api/ApiHandler'
import {
  setItems,
  pushItems,
  pushItem,
  removeItem,
  UpdatePayload,
  ItemImage,
  ImagePayload,
} from '.'
import { Admin } from '@/model/Admin'
import { Answer } from '@/model/Answer'
import { Player } from '@/model/Player'
import { ProjectData } from '@/model/Project'
import { JourneyData } from '@/model/Journey'

export interface StoreStateDashboard {
  players: Player[]
  admins: Admin[]
  answers: Answer[]
  projects: ProjectData[]
  projectsImages: ItemImage[]
  journeys: JourneyData[]
  journeysImages: ItemImage[]
  playerReport: string
}

export const useStoreDashboard = defineStore('dashboard', {
  state: (): StoreStateDashboard => ({
    players: [],
    admins: [],
    answers: [],
    projects: [],
    projectsImages: [],
    journeys: [],
    journeysImages: [],
    playerReport: '',
  }),
  actions: {
    // === Players === //
    async getAllPlayers(): Promise<ServerResponse<Player[]>> {
      const json = await apiHandler.get(`players`)
      this.players = setItems(this.players, json.data)
      return json
    },
    async registerPlayer(data: unknown): Promise<ServerResponse<Player>> {
      const json = await apiHandler.post(`users/register-player`, data)
      this.players = pushItem(this.players, json.data)
      return json
    },
    async updatePlayer({
      data,
      id,
    }: UpdatePayload<Player>): Promise<ServerResponse<Player[]>> {
      const json = await apiHandler.put(`players/${id}`, data)
      this.players = pushItem(this.players, json.data)
      return json
    },
    async getPlayer(id: number): Promise<ServerResponse<Player>> {
      const json = await apiHandler.get(`players/${id}`)
      this.players = pushItem(this.players, json.data)
      return json
    },
    async getPlayerByUser(id: number): Promise<ServerResponse<Player>> {
      const json = await apiHandler.get(`players/user/${id}`)
      this.players = pushItem(this.players, json.data)
      return json
    },
    async getPlayersByProject(id: string): Promise<ServerResponse<Player[]>> {
      const json = await apiHandler.get(`players/project/${id}`)
      this.players = pushItems(this.players, json.data)
      return json
    },
    async deletePlayer(id?: number): Promise<ServerResponse<void>> {
      const json = await apiHandler.delete(`players/${id}`)
      if (json.success) {
        this.players = removeItem(this.players, id)
      }
      return json
    },
    async getPlayerReport(id: number): Promise<ServerResponse<string>> {
      const json = await apiHandler.get(`players/${id}/report`)
      this.playerReport = json.data
      return json
    },
    async restartPlayer(
      playerId: number,
    ): Promise<ServerResponse<{ player: Player; answers: Answer[] }>> {
      const json = await apiHandler.post(`players/${playerId}/restart`)
      this.players = pushItem(this.players, json.data.player)
      this.answers = pushItems(this.answers, json.data.answers)
      return json
    },
    async reinitPlayer(
      playerId: number,
    ): Promise<ServerResponse<{ player: Player; answers: Answer[] }>> {
      const json = await apiHandler.post(`players/${playerId}/reinit`)
      if (json.data) {
        this.players = pushItem(this.players, json.data.player)
        this.answers = pushItems(this.answers, json.data.answers)
      }
      return json
    },
    async askActivatePlayer(playerId?: number): Promise<ServerResponse<void>> {
      const json = await apiHandler.post(`players/${playerId}/activate`)
      return json
    },

    // === Admins === //
    async getAllAdmins(): Promise<ServerResponse<Admin[]>> {
      const json = await apiHandler.get(`admins`)
      this.admins = setItems(this.admins, json.data)
      return json
    },
    async registerAdmin(data: unknown): Promise<ServerResponse<Admin>> {
      const json = await apiHandler.post(`users/register-admin`, data)
      this.admins = pushItem(this.admins, json.data)
      return json
    },
    async updateAdmin({
      data,
      id,
    }: UpdatePayload<Admin>): Promise<ServerResponse<Admin>> {
      const json = await apiHandler.put(`admins/${id}`, data)
      this.admins = pushItem(this.admins, json.data)
      return json
    },
    async getAdmin(id: number): Promise<ServerResponse<Admin>> {
      const json = await apiHandler.get(`admins/${id}`)
      this.admins = pushItem(this.admins, json.data)
      return json
    },
    async getAdminByUser(id: number): Promise<ServerResponse<Admin>> {
      const json = await apiHandler.get(`admins/user/${id}`)
      this.admins = pushItem(this.admins, json.data)
      return json
    },
    async getAdminsByProject(id: string): Promise<ServerResponse<Admin[]>> {
      const json = await apiHandler.get(`admins/project/${id}`)
      this.admins = pushItems(this.admins, json.data)
      return json
    },
    async deleteAdmin(id?: number): Promise<ServerResponse<void>> {
      const json = await apiHandler.delete(`admins/${id}`)
      if (json.success) {
        this.admins = removeItem(this.admins, id)
      }
      return json
    },
    async askActivateUser(userId?: number): Promise<ServerResponse<void>> {
      const json = await apiHandler.post(`users/${userId}/ask-activation`)
      return json
    },

    // === Projects === //
    async getAllProjects(): Promise<ServerResponse<ProjectData[]>> {
      const json = await apiHandler.get(`projects`)
      this.projects = setItems(this.projects, json.data)
      return json
    },
    async getProject(id: string): Promise<ServerResponse<ProjectData>> {
      const json = await apiHandler.get(`projects/${id}`)
      this.projects = pushItem(this.projects, json.data)
      return json
    },
    async addProjectImage({
      base64,
      id,
      file,
    }: ImagePayload): Promise<ServerResponse<string>> {
      const body = { base64: base64, file: file }
      const json = await apiHandler.post(`projects/${id}/image`, body)
      this.projectsImages = pushItem(this.projectsImages, {
        id,
        base64: json.data,
      })
      return json
    },
    async getProjectImage(id: string): Promise<ServerResponse<string>> {
      const json = await apiHandler.get(`projects/${id}/image`)
      this.projectsImages = pushItem(this.projectsImages, {
        id,
        base64: json.data,
      })
      return json
    },
    async deleteProjectImage(id: number): Promise<ServerResponse<void>> {
      const json = await apiHandler.delete(`projects/${id}/image`)
      if (json.success) {
        this.projectsImages = removeItem(this.projectsImages, id)
      }
      return json
    },

    // === Journeys === //
    async getAllJourneys(): Promise<ServerResponse<JourneyData[]>> {
      const json = await apiHandler.get(`journeys`)
      this.journeys = setItems(this.journeys, json.data)
      return json
    },
    async getJourneyByPlayer(
      id: number,
    ): Promise<ServerResponse<JourneyData[]>> {
      const json = await apiHandler.get(`players/${id}/journey`)
      this.journeys = pushItem(this.journeys, json.data)
      return json
    },
    async getJourney(id: string): Promise<ServerResponse<JourneyData>> {
      const json = await apiHandler.get(`journeys/${id}`)
      this.journeys = pushItem(this.journeys, json.data)
      return json
    },
    async addJourneyImage({
      base64,
      id,
      file,
    }: ImagePayload): Promise<ServerResponse<string>> {
      const body = { base64: base64, file: file }
      const json = await apiHandler.post(`journeys/${id}/image`, body)
      this.journeysImages = pushItem(this.journeysImages, {
        id,
        base64: json.data,
      })
      return json
    },
    async getJourneyImage(id: string): Promise<ServerResponse<string>> {
      const json = await apiHandler.get(`journeys/${id}/image`)
      this.journeysImages = pushItem(this.journeysImages, {
        id,
        base64: json.data,
      })
      return json
    },
    async deleteJourneyImage(id: number): Promise<ServerResponse<void>> {
      const json = await apiHandler.delete(`journeys/${id}/image`)
      if (json.success) {
        this.journeysImages = removeItem(this.journeysImages, id)
      }
      return json
    },

    // === Answers === //
    async getAllAnswers(): Promise<ServerResponse<Answer[]>> {
      const json = await apiHandler.get(`answers`)
      this.answers = setItems(this.answers, json.data)
      return json
    },
    async getAnswersByPlayer(id: number): Promise<ServerResponse<Answer[]>> {
      const json = await apiHandler.get(`players/${id}/answers`)
      this.answers = pushItems(this.answers, json.data)
      return json
    },
    async getAnswer(id: number): Promise<ServerResponse<Answer>> {
      const json = await apiHandler.get(`answers/${id}`)
      this.answers = pushItem(this.answers, json.data)
      return json
    },
  },
})

import { defineStore } from 'pinia'
import { Player } from '@/model/Player'
import { apiHandler, ServerResponse } from '@/api/ApiHandler'
import { JourneyData } from '@/model/Journey'
import { Answer, AnswerStep } from '@/model/Answer'
import { StageData } from '@/model/Stage'
import dayjs from 'dayjs'
import { ProjectData } from '@/model/Project'
import { localDb } from './LocalDb'

export interface StoreStateGame {
  player: Player | undefined
  report: string
  answers: Answer[]
  currentAnswer: Answer | undefined
  currentAnswerStep: AnswerStep | undefined
  project: ProjectData | undefined
  journey: JourneyData | undefined
  version: string | undefined
}

export const useStoreGame = defineStore('game', {
  state: (): StoreStateGame => ({
    get player(): Player | undefined {
      const key = `player`
      return localStorage.getItem(key)
        ? JSON.parse(localStorage.getItem(key) || '{}')
        : undefined
    },
    set player(player: Player | undefined) {
      const key = `player`
      localStorage.setItem(key, JSON.stringify(player || ''))
    },
    report: '',
    currentAnswer: undefined,
    currentAnswerStep: undefined,
    get answers(): Answer[] {
      const key = `answers`
      return localStorage.getItem(key)
        ? JSON.parse(localStorage.getItem(key) || '[]')
        : []
    },
    set answers(answers: Answer[]) {
      const key = `answers`
      localStorage.setItem(key, JSON.stringify(answers || ''))
    },
    get project(): ProjectData | undefined {
      const key = `project`
      return localStorage.getItem(key)
        ? JSON.parse(localStorage.getItem(key) || '{}')
        : undefined
    },
    set project(project: ProjectData | undefined) {
      const key = `project`
      localStorage.setItem(key, JSON.stringify(project || ''))
    },
    get journey(): JourneyData | undefined {
      const key = `journey`
      return localStorage.getItem(key)
        ? JSON.parse(localStorage.getItem(key) || '{}')
        : undefined
    },
    set journey(journey: JourneyData | undefined) {
      const key = `journey`
      localStorage.setItem(key, JSON.stringify(journey || ''))
    },
    get version(): string | undefined {
      const key = `version`
      return localStorage.getItem(key)
        ? localStorage.getItem(key) || ''
        : undefined
    },
    set version(version: string | undefined) {
      const key = `version`
      const oldVersion = localStorage.getItem(key) || ''
      if (oldVersion !== version) {
        localStorage.removeItem('answers')
        localStorage.removeItem('project')
        localStorage.removeItem('journey')
        localStorage.removeItem('version')
        localStorage.setItem(key, version || '')
      }
    },
  }),
  getters: {
    currentStage: (state): StageData | undefined => {
      return state.journey?.stages.find(
        (s) => s.id === state.currentAnswer?.stageId,
      )
    },
  },
  actions: {
    async getVersion(): Promise<ServerResponse<string>> {
      const json = await apiHandler.get(`players/version`)
      if (json.success) {
        if (json.data !== this.version) {
          await localDb.cleanImages()
        }
        this.version = json.data
      }
      return json
    },
    async getPlayer(): Promise<ServerResponse<Player>> {
      const json = await apiHandler.get(`players/self`)
      if (json.success) {
        this.player = json.data
      }
      return json
    },
    async start(alternative: boolean): Promise<ServerResponse<Player>> {
      const json = await apiHandler.post(`players/start`, {
        alternative,
      })
      if (json.success) {
        this.player = json.data
      }
      return json
    },
    completeStep(): void {
      if (!this.currentAnswerStep || !this.currentAnswer) {
        return console.warn('no current answer')
      }
      if (!this.currentAnswerStep.completed) {
        this.currentAnswerStep.completed = true
        this.currentAnswerStep.completedAt = dayjs().toDate()
      }

      const currentAnswer = this.currentAnswer
      currentAnswer.stepCount++

      if (currentAnswer.stepCount >= (currentAnswer.answerSteps?.length || 0)) {
        currentAnswer.completed = true
        currentAnswer.completedAt = dayjs().toDate()
        const newCurrentAnswer = this.answers.find(
          (a) => a.order === (currentAnswer?.order || 0) + 1,
        )
        this.selectCurrentAnswer(newCurrentAnswer?.id)
      } else {
        this.currentAnswerStep = currentAnswer?.answerSteps?.find(
          (as) => +as.order === (currentAnswer?.stepCount || 0) + 1,
        )
      }

      this.answers = this.answers.map((a) => {
        return a.order === currentAnswer?.order ? currentAnswer : a
      })

      // if (state === NEXT_STATE.UNLOCK) {
      //   this.unlockAnswer()
      //   this.nextStep()
      // } else if (state === NEXT_STATE.COMPLETE) {
      //   this.completeAnswer()
      //   this.nextStep()
      // } else if (state === NEXT_STATE.TERMINATE) {
      //   this.terminateAnswer()
      // } else {
      //   this.nextStep()
      // }
    },
    // updateState(state?: NEXT_STATE): void {
    //   if (state === NEXT_STATE.UNLOCK) {
    //     this.unlockAnswer()
    //     this.nextStep()
    //   } else if (state === NEXT_STATE.COMPLETE) {
    //     this.completeAnswer()
    //     this.nextStep()
    //   } else if (state === NEXT_STATE.TERMINATE) {
    //     this.terminateAnswer()
    //   } else {
    //     this.nextStep()
    //   }
    // },
    // completeAnswer(): void {
    //   if (!this.currentAnswer) return

    //   this.currentAnswer.completed = true
    //   this.currentAnswer.completedAt = dayjs().toDate()
    //   this.answers = this.answers.map((a) =>
    //     a.order === this.currentAnswer?.order ? this.currentAnswer : a,
    //   )
    // },
    // unlockAnswer(): void {
    //   if (!this.currentAnswer) return

    //   this.currentAnswer.unlocked = true
    //   this.currentAnswer.unlockedAt = dayjs().toDate()
    //   this.answers = this.answers.map((a) =>
    //     a.order === this.currentAnswer?.order ? this.currentAnswer : a,
    //   )
    // },
    // startAnswer(): void {
    //   if (!this.currentAnswer) return

    //   this.currentAnswer.started = true
    //   this.currentAnswer.startedAt = dayjs().toDate()
    //   this.answers = this.answers.map((a) =>
    //     a.order === this.currentAnswer?.order ? this.currentAnswer : a,
    //   )
    // },
    // terminateAnswer(): void {
    //   if (!this.currentAnswer) return

    //   this.currentAnswer.terminated = true
    //   this.currentAnswer.terminatedAt = dayjs().toDate()
    //   this.answers = this.answers.map((a) =>
    //     a.order === this.currentAnswer?.order ? this.currentAnswer : a,
    //   )

    //   const newCurrentAnswer = this.answers.find(
    //     (a) => a.order === (this.currentAnswer?.order || 0) + 1,
    //   )

    //   this.selectCurrentAnswer(newCurrentAnswer?.id)
    //   this.startAnswer()
    // },
    async getReport(): Promise<ServerResponse<string>> {
      const json = await apiHandler.get(`players/report`)
      if (json.success) {
        this.report = json.data
      } else {
        this.report = ''
      }
      return json
    },
    async askReward(player: Partial<Player>): Promise<ServerResponse<void>> {
      const json = await apiHandler.post(`players/ask-reward`, { player })
      return json
    },
    async satisfaction(
      score: string,
      comment: string,
    ): Promise<ServerResponse<void>> {
      const json = await apiHandler.post(`players/satisfaction`, {
        score,
        comment,
      })
      return json
    },
    async getProject(): Promise<ServerResponse<ProjectData> | boolean> {
      if (this.project) return true
      const json = await apiHandler.get(`players/project`)
      if (json.success) {
        this.project = json.data
      }
      return json
    },
    async getProjectByUsernameOrUrl(
      idUsernameOrUrl?: number | string,
    ): Promise<ServerResponse<ProjectData> | boolean> {
      if (this.project) return true
      const json = await apiHandler.get(`players/${idUsernameOrUrl}/project`)
      if (json.success) {
        this.project = json.data
      }
      return json
    },
    async getJourney(): Promise<ServerResponse<JourneyData> | boolean> {
      if (this.journey) return true
      const json = await apiHandler.get(`players/journey`)
      if (json.success) {
        this.journey = json.data
      }
      return json
    },
    async getImage(
      imageName: string,
    ): Promise<ServerResponse<string> | boolean> {
      if (await localDb.getItem(imageName)) {
        return true
      }
      const json = await apiHandler.post(`players/images`, { imageName })
      if (json.success) {
        await localDb.addItem({ name: imageName, base64: json.data })
      }
      return json
    },
    async getAnswers(): Promise<ServerResponse<Answer[]> | boolean> {
      const json = await apiHandler.get(`players/answers`)
      if (json.success) {
        this.answers = json.data
      }
      return json
    },
    async terminate(): Promise<ServerResponse<Player> | boolean> {
      const json = await apiHandler.post(`players/terminate`)
      if (json.success) {
        this.player = json.data
      }
      return json
    },
    selectCurrentAnswer(answerId: number | undefined): void {
      this.currentAnswer = this.answers.find((a) => a.id === answerId)
      this.currentAnswerStep = this.currentAnswer?.answerSteps?.find((as) => {
        return as.order === (this.currentAnswer?.stepCount || 0) + 1
      })
    },
    previousStep(): void {
      const currentAnswer = this.currentAnswer
      if (!currentAnswer) {
        return
      }

      if (currentAnswer.stepCount > 0) {
        currentAnswer.stepCount--
      }

      this.answers = this.answers.map((a) =>
        a.order === currentAnswer.order ? currentAnswer : a,
      )
    },
    askHint(): void {
      if (!this.currentAnswer || !this.currentAnswerStep) {
        return
      }

      this.currentAnswerStep.hintCount++
      this.currentAnswerStep.hintAt?.push(dayjs().format())
    },
    async syncAnswers(): Promise<ServerResponse<Answer[]>> {
      const json = await apiHandler.put(`players/answers`, {
        answers: this.answers.map((a) => {
          return {
            ...a,
            // stepAt: JSON.stringify(a.stepAt),
            // hintAt: JSON.stringify(a.hintAt),
          }
        }),
      })
      if (json.success) {
        await this.getPlayer()
        this.answers = json.data
      }
      return json
    },
    async resetProject(): Promise<void> {
      this.project = undefined
    },
  },
})

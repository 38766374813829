<template>
  <h1 class="game-title">
    <slot></slot>
  </h1>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.game-title {
  text-align: center;
  font-family: var(--game-title-font-family), sans-serif;
  font-weight: var(--game-title-font-weight);
  font-style: normal;
  margin: 0px;
  padding: 20px;
  color: var(--game-title-color);
}
</style>

/* eslint-disable no-console */

import { register } from 'register-service-worker'

console.log(
  process.env.NODE_ENV,
  process.env.VUE_APP_MODE,
  process.env.VUE_APP_API_URL,
)

if (
  process.env.NODE_ENV === 'production' ||
  process.env.VUE_APP_MODE === 'production'
) {
  console.log(`register : ${process.env.VUE_APP_API_URL}service-worker.js`)
  register(`${process.env.VUE_APP_API_URL}service-worker.js`, {
    ready(registration) {
      console.log(
        'App is being served from cache by a service worker.',
        registration,
      )
    },
    registered(registration) {
      console.log('Service worker has been registered.', registration)
      document.dispatchEvent(
        new CustomEvent('swRegistered', { detail: registration }),
      )
    },
    cached(registration) {
      console.log('Content has been cached for offline use.', registration)
    },
    updatefound(registration) {
      console.log('New content is downloading.', registration)
    },
    updated(registration) {
      console.log('New content is available; please refresh.', registration)
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        window.location.reload()
      })
      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: registration }),
      )
      registration.waiting?.postMessage({ type: 'SKIP_WAITING' })
    },
    offline() {
      console.log(
        'No internet connection found. App is running in offline mode.',
      )
      document.dispatchEvent(new CustomEvent('swOffline', { detail: true }))
    },
    error(error) {
      console.error('Error during service worker registration : ', error)
    },
  })
}

<template>
  <div class="field-data">
    <span class="label">
      <slot name="label" />
    </span>
    :
    <span class="value">
      <slot name="value" />
    </span>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.field-data {
  .label {
    color: grey;
  }
  .value {
    font-weight: 700;
  }
}
</style>

<template>
  <div class="base-card">
    <slot></slot>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.base-card {
  box-shadow: 1px 0px 6px 2px rgba(0, 0, 0, 0.19);
  border-radius: 6px;
  margin: 10px;
  padding: 10px 20px;
}
</style>

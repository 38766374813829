<template>
  <div class="game-text">
    <slot></slot>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss">
.game-text {
  text-align: var(--game-text-align);
  font-size: var(--game-text-font-size);
  color: var(--game-text-color);
  background-color: var(--game-text-background-color);
  border-radius: var(--game-text-border-radius);
  padding: 12px 24px;

  br {
    margin-bottom: 10px;
  }

  p:not(:last-child) {
    padding-bottom: 10px;
  }

  p {
    margin: 0px;
    padding: 0px;
  }
}

@media screen and (max-width: 768px) {
  .game-text {
    padding: 8px 16px;
  }
}

@media screen and (max-width: 600px) {
  .game-text {
    padding: 6px 12px;
  }
}
</style>

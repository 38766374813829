import { useStoreAuth } from '@/store/auth'
import { useStoreDashboard } from '@/store/dashboard'
import { NavigationGuardWithThis, RouteRecordRaw } from 'vue-router'
import ViewMain from '../views/admin/ViewMain.vue'

export const adminRoutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Main',
    component: ViewMain,
    redirect: 'home',
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import('../views/admin/home/ViewHome.vue'),
      },
      {
        path: 'create-player',
        name: 'CreatePlayer',
        component: () => import('../views/admin/home/ViewCreatePlayer.vue'),
      },
      {
        path: 'players',
        name: 'Players',
        component: () => import('../views/admin/home/ViewPlayers.vue'),
      },
      {
        path: 'admins',
        name: 'Admins',
        component: () => import('../views/admin/home/ViewAdmins.vue'),
      },
      {
        path: 'journeys',
        name: 'Journeys',
        component: () => import('../views/admin/home/ViewJourneys.vue'),
      },
      {
        path: 'players/:id',
        name: 'Player',
        component: () => import('../views/admin/home/ViewPlayer.vue'),
      },
      {
        path: 'admins/:id',
        name: 'Admin',
        component: () => import('../views/admin/home/ViewAdmin.vue'),
      },
      {
        path: 'players/:id/pdf',
        name: 'PlayerPdf',
        component: () => import('../views/admin/home/ViewPlayerPdf.vue'),
      },
      {
        path: 'account',
        name: 'Account',
        component: () => import('../views/admin/home/ViewMyAccount.vue'),
      },
      {
        path: 'projects',
        name: 'Projects',
        component: () => import('../views/admin/home/ViewProjects.vue'),
      },
      {
        path: 'projects/:id',
        name: 'Project',
        component: () => import('../views/admin/home/ViewProject.vue'),
      },
      {
        path: 'projects/:id/players',
        name: 'ProjectPlayers',
        component: () => import('../views/admin/home/ViewProjectPlayers.vue'),
      },
      {
        path: 'projects/:id/admins',
        name: 'ProjectAdmins',
        component: () => import('../views/admin/home/ViewProjectAdmins.vue'),
      },
      {
        path: 'journeys/:id',
        name: 'Journey',
        component: () => import('../views/admin/home/ViewJourney.vue'),
      },
    ],
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('../views/admin/home/ViewTest.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/admin/auth/ViewLogin.vue'),
  },
  {
    path: '/ask-reset-password',
    name: 'AskResetPassword',
    component: () => import('../views/admin/auth/ViewAskResetPassword.vue'),
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('../views/admin/auth/ViewResetPassword.vue'),
  },
  {
    path: '/activate-account',
    name: 'ActivateAccount',
    component: () => import('../views/admin/auth/ViewActivateAccount.vue'),
  },
  {
    path: '/404',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue'),
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404',
  },
]

export const adminGuard: NavigationGuardWithThis<undefined> = (
  to,
  from,
  next,
) => {
  const storeAuth = useStoreAuth()
  storeAuth.updatePage(to.name?.toString() || '')

  if (to.query.token) {
    storeAuth.setToken(`${to.query.token}`)
  }

  if (!storeAuth.isLoggedIn || storeAuth.isPlayer) {
    if (to.name !== 'Login' && to.name !== 'AskResetPassword') {
      next({ name: 'Login' })
    } else {
      next()
    }
  } else {
    next()
  }
}

<template>
  <div class="base-loader-1">
    <div class="clock">
      <div class="clock-head"></div>
      <div class="clock-center"></div>
      <div class="line-hour"></div>
      <div class="line-minute"></div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.base-loader-1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 20vw;
  height: 20vw;
  display: flex;
  justify-content: center;
  align-items: center;

  .clock {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
    min-height: 100%;
    border-radius: 50%;
    border: 2vw solid rgb(60, 60, 60);

    .clock-head {
      position: absolute;
      top: -40%;
      left: 60%;
      width: 8vw;
      height: 4vw;
      transform: rotate(25deg);
      border-radius: 50% 50% 0% 0%;
      border: 1.2vw solid rgb(60, 60, 60);
    }

    .clock-center {
      position: absolute;
      width: 0;
      height: 0;
      border-radius: 50%;
      border: 1vw solid rgb(60, 60, 60);
    }

    .clock-tick {
      position: absolute;
      width: 0.7vw;
      height: 0.7vw;
      background-color: rgb(60, 60, 60);
      top: -0.1vh;
      border-radius: 0% 0% 50% 50%;
      transform-origin: 0.4vw 10.15vw;

      &.main {
        width: 1vw;
        height: 1.2vw;
      }
    }

    .line-hour {
      position: absolute;
      bottom: 50%;
      width: 1.2vw;
      height: 5vw;
      border-radius: 1vw;
      background-color: rgb(60, 60, 60);
      transform-origin: bottom;
      animation: rotate 3s ease-in-out infinite;
    }

    .line-minute {
      position: absolute;
      bottom: 50%;
      width: 1vw;
      height: 7vw;
      border-radius: 1vw;
      background-color: rgb(60, 60, 60);
      transform-origin: bottom;
      animation: rotate 1.2s ease-in-out infinite;
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

import { openDB, IDBPDatabase } from 'idb'

export interface LocalDbImage {
  name: string
  base64: string
}

export class LocalDb {
  public db!: IDBPDatabase

  constructor() {
    if (!('indexedDB' in window)) {
      console.log(`This browser doesn't support IndexedDB`)
      return
    }
  }

  async open() {
    this.db = await openDB('Images', 1, {
      upgrade(db) {
        const store = db.createObjectStore('images', {
          keyPath: 'id',
          autoIncrement: true,
        })
        store.createIndex('name', 'name', { unique: true })
      },
    })
  }

  async close() {
    this.db.close()
  }

  async cleanImages() {
    await this.open()
    await this.db.clear('images')
    await this.close()
  }

  async addItem(value: LocalDbImage): Promise<string> {
    await this.open()
    try {
      await this.db.add('images', value)
    } catch (e) {
      const item = await this.getItem(value.name)

      if (item) {
        item.base64 = value?.base64 || ''
      }

      try {
        await this.db.put('images', item)
      } catch (e) {}
    }
    await this.close()
    return ''
  }

  async getItem(name: string): Promise<LocalDbImage | undefined> {
    await this.open()
    const item = (await this.db.getAllFromIndex('images', 'name', name))[0]
    await this.close()

    return item
  }
}

export const localDb = new LocalDb()
